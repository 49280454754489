var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"votes fs-6"},[_c('button',{class:[
      'btn btn-sm ms-2 me-1 upvote px-1 text-white-50',
      { active: _vm.userUpvoted },
    ],attrs:{"disabled":_vm.voting},on:{"click":function($event){return _vm.updateVotes('upvote')}}},[_c('b-icon-chevron-up',{staticClass:"fs-6"})],1),_c('small',{},[_vm._v(_vm._s(_vm.upvotes))]),_c('button',{class:[
      'btn btn-sm ms-1 downvote px-1 text-white-50',
      { active: _vm.userDownoted },
    ],attrs:{"disabled":_vm.voting},on:{"click":function($event){return _vm.updateVotes('downvote')}}},[_c('b-icon-chevron-down',{staticClass:"fs-6"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }